.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(80,126,163,1) 100%);
  color: #35495E;
  margin: 0;
  padding: 0;
}
.app-header {
  border: 0px solid #2980b9;
  border-bottom: none;
  padding: 0%;
  background: rgba(44, 62, 80, 0.66);
}
.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border: 0px solid #2980b9;
  background: linear-gradient(rgba(79, 82, 84, 0.7) 0%, rgba(36, 44, 51, 0.7) 100%);
  padding: 0%;
  z-index: 7
}
.app-footer-alt {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  color: rgba(134, 142, 150, 0.66);
  padding: 1rem;
  border: 1px solid #2980b9;
  padding: 0%;
  font-size: 15px;
  background: rgba(29, 40, 52, 0.69);
  text-align: center;
}
.app-body {
  display: flex;
  flex: 1;
  background: transparent;
  flex-direction: row;
}
.app-content {
  flex: 1;
  border: 0px solid #2980b9;
  background: transparent;
  color: #ddd;
}
.app-nav, .app-ads {
  /* 12em is the width of the columns */
  /* flex: 0 0 1em; */
  width: 0px; /*hide app-nav as menu overlaid */
  border: 0px solid #ccc;
  padding: 0%;
  background: transparent;
  color: #ddd
}
.app-nav {
  /* put the nav on the left */
  order: -1;
}

@media (min-width: 768px) {
  .app-body {
    flex-direction: row;
    flex: 1;
  }
  .app-content {
    flex: 1;
  }
  .app-nav, .app-ads {
    /* 12em is the width of the columns */
    /* flex: 0 0 1em; */
    /*hide app-nav as menu overlaid */
  }
}

